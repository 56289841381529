import React, { useRef } from 'react';
import '../index.css';

function TextForm() {
    const promptRef = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();
        function toggleSpinner(visible) {
            const spinnerElement = document.getElementById("spinner");
            if (spinnerElement) {
                spinnerElement.style.display = visible ? "inline-block" : "none";
            }
        }
        // Text form submission
        document.getElementById("text-form").addEventListener("submit", async function (event) {
            event.preventDefault();

            const prompt = document.getElementById("prompt").value;
            console.log("Prompt value:", prompt);

            try {
                toggleSpinner(true);

                const response = await fetch("/generate_text", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        prompt: prompt,
                    }),
                });

                console.log("Response:", response);
                const data = await response.json();
                console.log("Data:", data);

                document.getElementById("generated-text").textContent = data.generated_text || data.error;
            } catch (error) {
                console.error("Error sending text prompt:", error);
            } finally {
                toggleSpinner(false);
            }
        });

    };

    return (
        <form id="text-form" onSubmit={handleSubmit}>
            <label htmlFor="prompt">Enter a text prompt:</label>
            <input type="text" ref={promptRef} name="prompt" required />
            <button type="submit">Generate Text</button>
        </form>
    );
}

export default TextForm;

import React, { useEffect, useState } from 'react';
import '../index.css'; // replace with your actual css file path
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom"
import apiRequest from '../utils/api';

async function fetchProjects(getAccessTokenSilently, user) {
  //return await apiRequest(`${process.env.REACT_APP_API_URL}/projects?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently);
  console.log('API URL:', process.env.REACT_APP_API_URL);
  return await apiRequest(`${process.env.REACT_APP_API_URL}/projects?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently);

}

async function createProject(getAccessTokenSilently, user, name) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/projects?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'POST', { name });
}

const updateProject = async (getAccessTokenSilently, name, tags, user, projectId) => {
  const vals = {
    name: name,
    tags: tags,
  }
  return await apiRequest(`${process.env.REACT_APP_API_URL}/projects/${projectId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'PUT', vals);
};

async function deleteProject(getAccessTokenSilently, user, projectId) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/projects/${projectId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'DELETE');
}

const ProjectList = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [projects, setProjects] = useState([]);
  const [editFields, setEditFields] = useState({ id: null, name: '', tags: [''] });

  useEffect(() => {
    const submitFetchProjects = async () => {
      const data = await fetchProjects(getAccessTokenSilently, user);
      console.log('Projects:', data)
      setProjects(data.projects);
    }
    submitFetchProjects();
  }, [getAccessTokenSilently, user]);

  const addProject = async (name) => {
    const project = await createProject(getAccessTokenSilently, user, name);
    setProjects([...projects, project]);
    // TODO: Figure out how to correctly render the new project without fetching all projects again
    const data = await fetchProjects(getAccessTokenSilently, user);
    setProjects(data.projects);
  };

  const editProject = (projectId) => {
    const project = projects.find(project => project._id === projectId);
    setEditFields({ id: projectId, name: project.name, tags: [...project.tags] });
  };

  const deleteProjectHandler = async (projectId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      await deleteProject(getAccessTokenSilently, user, projectId);
      setProjects(projects.filter(project => project._id !== projectId));
    }
  };

  return (
    <div>
      <div className='projectHeader'>
        <h3>Projects</h3>
        <div className='outputcontrols'>
        <button id="record" onClick={() => addProject('New Project Name')}>New Project</button>
        </div>
      </div>
      
      {projects.map((project, index) => (
        <div className="project-card">
          {editFields.id === project._id ? (
            <>
              <button id="delete" onClick={() => deleteProjectHandler(project._id)}>
                <span className="delete-icon">&#215;</span>
              </button>
              <div /* include any styling you need here */
                key={index}
              >
                <div className="project-name">
                  <input
                    type="text"
                    value={editFields.name}
                    onChange={(e) => setEditFields({ ...editFields, name: e.target.value })}
                  />
                </div>
                <div className="project-tags">
                  <input
                    type="text"
                    value={editFields.tags.join(', ')}
                    onChange={(e) => setEditFields({ ...editFields, tags: e.target.value.split(',').map(tag => tag.trim()) })}
                  />
                </div>
              </div>
              <button id="save"
                onClick={async () => {
                  await updateProject(getAccessTokenSilently, editFields.name, editFields.tags, user, project._id);
                  const data = await fetchProjects(getAccessTokenSilently, user);
                  setProjects(data.projects);
                  setEditFields({ id: null, name: '', tags: [''] });
                }}>
                <span className="save-icon">&#128190;</span>
              </button>
            </>
          ) : (
            <>
              <button id="delete" onClick={() => deleteProjectHandler(project._id)}>
                <span className="delete-icon">&#215;</span>
              </button>
              <button id="edit" onClick={() => editProject(project._id)}>
                <span className="edit-icon">&#9998;</span>
              </button>
              <Link
                to={`/project/${project._id}`}
                state={{
                  name: project.name,
                  tags: project.tags,
                }}
                key={index}
              >
                <div className="project-name">{project.name}</div>
                <div className="project-tags">{project.tags && project.tags.length > 0 ? project.tags.join(', ') : ""}</div>
              </Link>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProjectList;

// Record Audio

import React, { useEffect, useRef } from 'react';
import '../index.css';
import { useAuth0 } from "@auth0/auth0-react";
import apiRequest from '../utils/api';
import {ReactComponent as Record} from './icons/Record.svg';
import {ReactComponent as Stop} from './icons/Stop.svg';

async function submitAudio(blob, onGeneratedText, getAccessTokenSilently, user, animationId, drawSineWave, projectId) {
    try {
        const formData = new FormData();
        formData.append('audio', blob, 'recorded_audio.webm');
        await apiRequest(`http://localhost:8000/audio/${projectId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'POST', formData);
        if (onGeneratedText) {
            console.log('Calling onGeneratedText')
            onGeneratedText("inputs");
            cancelAnimationFrame(animationId);
            drawSineWave();
        }
    } catch (error) {
        console.error('Error submitting audio:', error);
    }
}

function RecordAudio({ onGeneratedText, projectId, isLoading}) {
    const recordButtonRef = useRef();
    const stopButtonRef = useRef();
    const audioWaveCanvasRef = useRef();
    const { getAccessTokenSilently, user } = useAuth0();

    let animationId; // To hold the id of the animation
    const sineWaveFreq = 50; // Frequency of the sine wave
    let t = 0; // Time variable for the sine wave


    let loadingBarProgress = 0;
    let loadingBarDirection = 1;
    const loadingBarSpeed = 0.01;


    useEffect(() => {
        let mediaRecorder;
        let recordedChunks = [];
        let audioContext;
        let analyser;
        let audioSource;

        const audioWaveCanvas = audioWaveCanvasRef.current;
        const audioWaveContext = audioWaveCanvas.getContext('2d');

        function toggleAudioWave(visible) {
            audioWaveCanvas.style.display = visible ? 'block' : 'none';
        }

        function drawLoadingBar() {
            audioWaveContext.clearRect(0, 0, audioWaveCanvas.width, audioWaveCanvas.height);
            audioWaveContext.fillStyle = 'rgb(100,100,100)';
            const barWidth = loadingBarProgress * audioWaveCanvas.width;
            audioWaveContext.fillRect(0, audioWaveCanvas.height / 2 - 10, barWidth, 20);
            loadingBarProgress += loadingBarDirection * loadingBarSpeed;
            if (loadingBarProgress > 1) {
                loadingBarProgress = 1;
                loadingBarDirection = -1;
            } else if (loadingBarProgress < 0) {
                loadingBarProgress = 0;
                loadingBarDirection = 1;
            }
            animationId = requestAnimationFrame(drawLoadingBar);
        }

        function drawSineWave() {
            audioWaveContext.clearRect(0, 0, audioWaveCanvas.width, audioWaveCanvas.height);
            audioWaveContext.beginPath();

            for (let x = 0; x < audioWaveCanvas.width; x++) {
                const y = audioWaveCanvas.height / 2 + (audioWaveCanvas.height / 4) * Math.sin((2 * Math.PI * x) / audioWaveCanvas.width + t);
                if (x === 0) {
                    audioWaveContext.moveTo(x, y);
                } else {
                    audioWaveContext.lineTo(x, y);
                }
            }

            audioWaveContext.strokeStyle = '#DB5BFB';
            audioWaveContext.lineWidth = 2;
            audioWaveContext.stroke();

            t += 0.05;
            animationId = requestAnimationFrame(drawSineWave);
        }


        function drawAudioWave() {
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyser.getByteFrequencyData(dataArray);

            audioWaveContext.clearRect(0, 0, audioWaveCanvas.width, audioWaveCanvas.height);
            const barWidth = (audioWaveCanvas.width / bufferLength) * 2.5;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                barHeight = (dataArray[i] / 2) * 2
                audioWaveContext.fillStyle = 'rgb(' + (barHeight + 100) + ',50,50)';
                audioWaveContext.fillRect(x, audioWaveCanvas.height - barHeight / 2, barWidth, barHeight);

                x += barWidth + 1;
            }

            requestAnimationFrame(drawAudioWave);
        }

        async function initMediaDevices() {
            console.log('Initializing media devices...');
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const options = { mimeType: 'audio/webm; codecs=opus' };
                mediaRecorder = new MediaRecorder(stream, options);
                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        recordedChunks.push(event.data);
                    }
                };

                audioContext = new AudioContext();
                analyser = audioContext.createAnalyser();
                audioSource = audioContext.createMediaStreamSource(stream);
                audioSource.connect(analyser);

                drawSineWave();

                // Implement onstop and other necessary event handlers for mediaRecorder

            } catch (error) {
                console.error('Error initializing media devices:', error);
            }
        }

        function startRecording() {
            console.log('Start recording');
            cancelAnimationFrame(animationId);
            drawAudioWave();
            if (mediaRecorder) {
                mediaRecorder.start();
                recordButtonRef.current.style.display = 'none';
                stopButtonRef.current.style.display = 'block';
                stopButtonRef.current.disabled = false;
                toggleAudioWave(true);
            } else {
                console.error('MediaRecorder not initialized');
            }
        }

        function stopRecording() {
            console.log('Stop recording');
            if (mediaRecorder && mediaRecorder.state !== 'inactive') {
                mediaRecorder.onstop = async () => {
                    stopButtonRef.current.style.display = 'none';
                    recordButtonRef.current.style.display = 'block';
                    cancelAnimationFrame(animationId);
                    drawLoadingBar();
                    const blob = new Blob(recordedChunks, { type: 'audio/webm' });
                    await submitAudio(blob, onGeneratedText, getAccessTokenSilently, user, animationId, drawSineWave, projectId);
                };
                mediaRecorder.stop();
            } else {
                console.error('MediaRecorder not recording or not initialized');
            }
        }

        initMediaDevices();

        recordButtonRef.current.addEventListener('click', startRecording);
        stopButtonRef.current.addEventListener('click', stopRecording);

        if (isLoading) {
            cancelAnimationFrame(animationId);
            drawLoadingBar();
        } else if (!isLoading) {
            cancelAnimationFrame(animationId);
            drawSineWave();
        }

        return () => {
            if (recordButtonRef.current) {
                recordButtonRef.current.removeEventListener('click', startRecording);
                stopButtonRef.current.removeEventListener('click', stopRecording);
            }
        };
    }, [user, isLoading]);

    return (
        <>
            <div class="recording-control-row">
                <canvas
                    ref={audioWaveCanvasRef}
                    className="audio-wave"
                    width="600"
                    height="100"
                    style={{ display: 'block' }}
                ></canvas>
                <button id="record" ref={recordButtonRef}>
                    <Record className="icon"/>
                </button>
                <button id="stop" ref={stopButtonRef} disable style={{ display: 'none' }}>
                    <Stop className="icon"/>
                </button>
            </div>
        </>
    );
}

export default RecordAudio;
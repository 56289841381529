import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton, LogoutButton } from "../components/general_components/LoginLogout";
import ProjectList from "../components/projects";
import Landing from '../components/Landing'
import Nav from '../components/general_components/navbar';
import Footer from '../components/general_components/Footer';

const Root = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    if (isAuthenticated) {
        return (
            <div class="container">
                <Nav />
                <ProjectList />
                <Footer />
            </div>
        );
    } else {
        return (
            <Landing/>
        );
    }
}

export default Root;

import React, { useRef } from 'react';
import { LoginButton } from '../components/general_components/LoginLogout';
import { Link, useNavigate } from "react-router-dom"
import '../index.css';
import { ReactComponent as Logo } from '../components/Logo.svg'; // Tell webpack this JS file uses this image
import Footer from '../components/general_components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Nav from '../components/general_components/navbar';

function Thankyou() {
    const heroAnimationRef = useRef(null);

    let navigate = useNavigate();

    return (
        <body className="landing-body">
            <Nav />
            <div className="container container-body">
            <div className='tier-container'>
                    <div className="contact-form thankyou">
            <div className="tiercardheader">
                            <h3 className="tierheader">You're on the waitlist</h3>
                            <h4>We'll be in touch soon</h4>
                            <hr></hr>
                            <ul className="benefits">
                            <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Access premium content types like story-driven episodes and panel discussions</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Weave relevant live news and twitter references into your content with a button click</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Add relevant references to your past content automatically</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Generate accompanying tweets to promote your content</li>
                            </ul>
                        </div>
                        <LoginButton text="Back to Thoughtfilter" className="primary-button" />
            </div>
            </div>
            </div>
            <Footer />
        </body>
    );
}

export default Thankyou;

// Project Workspace

import React, { useState, useEffect } from 'react';
import '../index.css';
import RecordAudio from '../components/RecordAudio';
import TextForm from '../components/TextForm';
import GeneratedText from '../components/GeneratedText';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import InputList from '../components/Inputs';
import Nav from '../components/general_components/navbar';

export default function ProjectWorkspace() {
    const { projectId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { name, tags } = location.state || { name: '', tags: [] };
    const [outputsVersion, setOutputsVersion] = useState(0);
    const [inputsVersion, setInputsVersion] = useState(0); // New state variable
    const [inputs, setInputs] = useState([]);

  const handleInputs = (inputs) => {
      setInputs(inputs);
      console.log("Input handler has been called in project workspace:" + inputs)
  };

    const handleLoading = (loading) => {
        setIsLoading(loading);
    };
    
    useEffect(() => {
        console.log("New versions (input, output): " + inputsVersion + ", " + outputsVersion);
    }, [inputsVersion, outputsVersion, isLoading]); 

    const handleGeneratedText = async (text) => {
        if (text == "outputs") {
            setOutputsVersion((prev) => prev + 1); // Increment the inputs version number
            console.log("New output version: " + outputsVersion);
        } else {
            setInputsVersion((prev) => prev + 1); // Increment the inputs version number
            console.log("New input version: " + inputsVersion);
        }
    };

    return (
        <div className="container">
            <Nav />
            <div className="page-header">
                <div className="project-name">{name}</div>
                <div className="project-tags">{tags.join(', ')}</div>
            </div>
            <div className="spinner" id="spinner" style={{ display: 'none' }}></div>
            <RecordAudio isLoading={isLoading} onGeneratedText={handleGeneratedText} projectId={projectId} />
            <InputList handleInputs={handleInputs} projectId={projectId} version={inputsVersion} />
            <TextForm />
            <GeneratedText handleGeneratedText={handleGeneratedText} setOutputsVersion={setOutputsVersion} version={outputsVersion} inputs={inputs} handleLoading={handleLoading} projectId={projectId}/>
        </div>
    )
}
import React from "react";
import '../../index.css';
import { Link } from "react-router-dom"

function Footer() {
    return <div className="footer">
        <ul className="footer">
            <li><Link to="https://app.termly.io/document/privacy-policy/7682e628-d25c-40b5-b4a3-8d1461ec2d41">Privacy</Link></li>
            <li>|</li>
            <li><Link to="https://app.termly.io/document/terms-of-service/c01e162e-8dfb-4ef3-a712-0bc9ebbc7b6a">Terms of Use</Link></li>
        </ul>
    </div>
}

export default Footer;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";


const history = createBrowserHistory();

 const onRedirectCallback = (appState) => {
   history.push(
     appState && appState.returnTo ? appState.returnTo : window.location.pathname
   );
};

{/*
const projects = [
  { name: 'Project 1', tags: ['tag1', 'tag2', 'tag3'] },
  { name: 'Project 2', tags: ['tag1', 'tag2'] },
  // more projects...
];
*/}

ReactDOM.render(
  <Auth0Provider
    domain="dev-u16s26b8czq1iq7i.us.auth0.com"
    clientId="71jdfhUa32vKwmNEdqjn0zmmz5BCHqCS"
    onRedirectCallback={onRedirectCallback}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: 'https://thoughtfilter.app',
    }}
  >
    {/* <RouterProvider router={router} /> */}
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useRef } from 'react';
import { LoginButton } from '../components/general_components/LoginLogout';
import { Link, useNavigate } from "react-router-dom"
import '../index.css';
import { ReactComponent as Logo } from '../components/Logo.svg'; // Tell webpack this JS file uses this image
import Footer from '../components/general_components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Nav from '../components/general_components/navbar';

function Waitlist({title}) {
    const heroAnimationRef = useRef(null);

    let navigate = useNavigate();

    return (
        <body className="landing-body">
            <Nav />
            <div className="container container-body">
                <h1>{title}</h1>
                <p>Get early access to premium content types, live news integration, semantic content catalog referencing, and much more. </p>
                <div className='tier-container'>
                    <div className="contact-form">
                        <div className="tiercardheader"> 
                        <form action="https://protonmail.us1.list-manage.com/subscribe/post" method="POST">
                            <input type="hidden" name="u" value="1cebd621912ce96773b7d2944" />
                            <input type="hidden" name="id" value="e7d566f15b" />

                            <label for="MERGE1">First name</label>
                            <input id="MERGE1" type="text" name="MERGE1" placeholder="First name" />

                            <label for="MERGE2">Last name</label>
                            <input id="MERGE2" type="text" name="MERGE2" placeholder="Last name" />

                            <label for="MERGE0">Email address</label>
                            <input id="MERGE0" type="text" name="MERGE0" placeholder="Email" />

                            <label for="MERGE6">Which premium feature excites you most?</label>
                            <input id="MERGE6" type="text" name="MERGE6" placeholder="Eternal life" />
                            <input className="primary-button" type="submit" value="Join us" name="submit" />
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </body>
    );
}

export default Waitlist;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import '../../index.css';

const LogoutButton = ({text, id}) => {
  const { logout } = useAuth0();

  return (
    <button id={id ? id : "logout"} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      {text}
    </button>
  );
};

const LoginButton = ({text, id}) => {
  const { loginWithRedirect } = useAuth0();
return (
  <button id={id ? id : "login"} onClick={() => loginWithRedirect()}>
  {text}
  </button>
  )
};

export { LogoutButton, LoginButton };
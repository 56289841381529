import React, { useEffect, useState } from 'react';
import '../index.css'; // replace with your actual css file path
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom"
import apiRequest from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Dropdown from './general_components/Dropdown';
import BeatLoader from "react-spinners/BeatLoader";

async function generateText(handleGeneratedText, inputs, handleLoading, getAccessTokenSilently, user, outputType, projectId, setLoading, setOutputs) {
  try {
    // This line needs to properly append the text to the formData
    setLoading(true);
    var aggregate_inputs = inputs.map(input => input.text).join(" ");
    const formData = new FormData();
    formData.append('inputs', aggregate_inputs);
    formData.append('output_type', outputType);
    handleLoading(true);

    console.log("Making POST request to generate text endpoint")
    const response = await apiRequest(`${process.env.REACT_APP_API_URL}/generate_text/${projectId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'POST', formData);
    console.log("Response from generate text endpoint: ", response);
    const taskId = response.task_id;
    console.log("taskID", taskId);

    // Poll the API until the task is complete
    let taskStatus = null;
    do {
      taskStatus = await checkTaskStatus(taskId, getAccessTokenSilently);
      if (taskStatus.state !== 'PENDING') break;
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait 3 seconds before polling again
    } while (true);

    const outputsData = await fetchOutputs(getAccessTokenSilently, user, projectId);
    setOutputs(outputsData.outlines);
   
    // handleGeneratedText("outputs");
    handleLoading(false);
    setLoading(false);
  } catch (error) {
    console.error('Error generating text:', error);
  }
}

//Check task status
async function checkTaskStatus(taskId, getAccessTokenSilently) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/task_status/${taskId}`, getAccessTokenSilently);
}

async function fetchOutputs(getAccessTokenSilently, user, projectId) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/generate_text/${projectId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently);
}

async function deleteOutput(getAccessTokenSilently, user, outputId) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/generate_text/${outputId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'DELETE');
}

const options = [
  { value: 'outline', label: 'Research-Driven Podcast'},
  { value: 'interview-guide', label: 'Interview Guide' },
  { value: 'story', label: 'Story-Driven Podcast', tag: 'Premium' },
  { value: 'panel', label: 'Panel Discussion', tag: 'Premium' },
  // more options here...
];

const OutputCard = ({ output, deleteOutputHandler, index }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSize = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="project-card" key={index}>
    <>
      <p className="name">{output.headline}</p>
      <p className="project-tags">{output.date}</p>
      <pre className="body" style={{display: isExpanded ? 'block' : 'none'}}>{output.text}</pre>
      <button id="delete" onClick={() => deleteOutputHandler(output._id)}>
        <span className="delete-icon">&#215;</span>
      </button>
      <button id="expand" onClick={toggleSize}>
        <FontAwesomeIcon id="expand" icon={faCaretDown} />
      </button>
    </>
  </div>
  )
}

const GeneratedText = ({ handleGeneratedText, version, inputs, handleLoading, projectId, }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [outputs, setOutputs] = useState([]);
  const [outputType, setOutputType] = useState("outline");
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleSize = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const submitFetchOutputs = async (projectId) => {

      const data = await fetchOutputs(getAccessTokenSilently, user, projectId);
      console.log('Fetched outputs:', data)
      setOutputs(data.outlines);

    }
    submitFetchOutputs(projectId);
  }, [version, getAccessTokenSilently, user, projectId, loading]);

  const deleteOutputHandler = async (outputId) => {
    if (window.confirm("Are you sure you want to delete this recording?")) {
      console.log(outputId)
      await deleteOutput(getAccessTokenSilently, user, outputId);
      setOutputs(outputs.filter(output => output._id !== outputId));
    }
  };

  return (
    <>
      <div className='outputHeader'>
        <h3>Outputs</h3>
        <div className='outputcontrols'>
            <Dropdown 
              options={options}
              initialValue={outputType}
              onValueChange={(value) => setOutputType(value)}/>
          <button id="record" onClick={() => generateText(handleGeneratedText, inputs, handleLoading, getAccessTokenSilently, user, outputType, projectId, setLoading, setOutputs)}>
            {loading ? <BeatLoader size={10} color={"#FFFFFF"} /> : 'Generate'}
          </button>
        </div>
       
      </div>
      {outputs && outputs.map((output, index) => (
        <OutputCard output={output} deleteOutputHandler={deleteOutputHandler} index={index}/>
      ))}
    </>
  );
};

export default GeneratedText;
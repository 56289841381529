// App.js

import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from './routes/root';
import ErrorPage from "./error-page";
import ProjectWorkspace from './routes/project';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Upgrade from './routes/upgrade';
import Waitlist from './routes/waitlist';
import Thankyou from './routes/thankyou';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />
    },
    {
      path: "/upgrade",
      element: <Upgrade />,
      errorElement: <ErrorPage /> 
    },
    {
      path: "/waitlist",
      element: <Waitlist />,
      errorElement: <ErrorPage /> 
    },
    {
      path: "/thankyou",
      element: <Thankyou />,
      errorElement: <ErrorPage />
    },
    {
      path: "/project/:projectId",
      element: <ProjectWorkspace />,  // assuming you have a component named ProjectWorkspace
      errorElement: <ErrorPage />
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;

import React, { useRef, useState } from 'react';
import { LoginButton } from '../components/general_components/LoginLogout';
import { Link, useNavigate } from "react-router-dom"
import '../index.css';
import { ReactComponent as Logo } from '../components/Logo.svg'; // Tell webpack this JS file uses this image
import Footer from '../components/general_components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Toggle from '../components/Toggle'

function Upgrade() {
    const heroAnimationRef = useRef(null);
    let navigate = useNavigate();

    const [pricingPeriod, setPricingPeriod] = useState('Annual');

    const handleToggle = (isToggled) => {
        const period = isToggled ? 'Annual' : 'Monthly';
        setPricingPeriod(period);
        console.log(`Selected pricing period: ${period}`);
    }

    return (
        <body className="landing-body">
            <header>
            <ul className="nav">
                    <a className="logoLink" onClick={()=> navigate('/')}><Logo className="logo" /></a>
                    <div className="nav-items">
                    <li className="nav-item"><a onClick={()=> navigate('/upgrade')}>Pricing</a></li>
                    <li className="nav-item"><a onClick={()=> navigate('/contact')}>Contact Us</a></li>
                    <LoginButton text="Sign up" id="navButton"/>
               </div>
            </ul> 
            </header>
            <div className="container container-body">
                <h1>Pricing</h1>
                <p>Try Thoughtfilter for free. Upgrade for growth features that will help you get noticed faster.</p>
                <div className='tier-container'>
                    <div className="tier">
                        <div className="tiercardheader">
                            <h3 className="tierheader">Free</h3>
                            <h3>$0/mo</h3>
                            <Toggle defaultChecked={true} labels={["Monthly", "Annual"]} onToggle={handleToggle} />
                            <hr></hr>
                            <ul className="benefits">
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Create AI powered outlines from raw inputs</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Access basic content types</li>
                            </ul>
                        </div>
                        <LoginButton text="Get started" className="primary-button" />
                    </div>
                    <div className="tier pro">
                        <div className="tiercardheader">
                            <h3 className="tierheader">Pro</h3>
                            <h3>$49.99/mo</h3>
                            <Toggle defaultChecked={true} labels={["Monthly", "Annual"]} onToggle={handleToggle} />
                            <hr></hr>
                            <ul className="benefits">
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Access premium content types like story-driven episodes and panel discussions</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Weave relevant live news and twitter references into your content with a button click</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Add relevant references to your past content automatically</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Generate accompanying tweets to promote your content</li>
                            </ul>
                        </div>
                        <button
                            className="primary-button"
                            onClick={() => navigate('/waitlist')}
                        >
                            Join waitlist</button>
                    </div>

                    <div className="tier enterprise">
                        <div className="tiercardheader">
                            <h3 className='tierheader'>Enterprise</h3>
                            <h3>Custom</h3>
                            <Toggle defaultChecked={true} labels={["Monthly", "Annual"]} onToggle={handleToggle} />
                            <hr></hr>
                            <ul className="benefits">
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Manage multiple podcasts or newsletters</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Add users with editor or viewer roles</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />SSO integration</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Automatically include relevant internal knowledge in your content</li>
                                <li><FontAwesomeIcon id="check-icon" icon={faCheck} />Track performance stats and iterate accordingly</li>
                            </ul>
                        </div>

                        <button
                            className="primary-button"
                            onClick={() => navigate('/contact')}
                        >
                            Join waitlist</button>

                    </div>
                </div>

            </div>
            <Footer />
        </body>
    );
}

export default Upgrade;

export default async function apiRequest(url, getAccessTokenSilently, method = 'GET', body = null) {
    const accessToken = await getAccessTokenSilently();
    const options = {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    };
    if(body) {
      if(method === 'POST') {
        if (typeof body === 'object' && body instanceof FormData) {
            options.body = body
        }
        else {
            const formData = new FormData();
            formData.append('name', body.name);
            options.body = formData;
        }
      } else if(method === 'PUT' || method === 'PATCH') {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(body);
      }
    }
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }
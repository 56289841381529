import React, { useState } from 'react';

const Toggle = ({ defaultChecked, labels, onToggle }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onToggle(!isChecked);
  }

  return (
    <div className="toggle-container">
      <label className="toggle-label">{labels[0]}</label>
      <div className="toggle-switch" onClick={handleToggle}>
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="toggle-slider"></span>
      </div>
      <label className="toggle-label">{labels[1]}</label>
    </div>
  );
};

export default Toggle;
// Input List

import React, { useEffect, useState } from 'react';
import '../index.css'; // replace with your actual css file path
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom"
import apiRequest from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

async function fetchInputs(getAccessTokenSilently, user, projectId) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/audio/${projectId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently);
}

async function deleteInput(getAccessTokenSilently, user, inputId) {
  return await apiRequest(`${process.env.REACT_APP_API_URL}/audio/${inputId}?user_sub=${encodeURIComponent(user.sub)}`, getAccessTokenSilently, 'DELETE');
}

const InputCard = ({ input, deleteInputHandler }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSize = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div id={input._id} className="input-card">
      <div className='inputHeader'>     
        <p className="name">Excerpt: {input.name}</p>
        <p className="project-tags">{input.date}</p>
      </div>
      <p className="body" style={{display: isExpanded ? 'block' : 'none'}}>{input.text}</p>
      <button id="delete" onClick={() => deleteInputHandler(input._id)}>
        <span className="delete-icon">&#215;</span>
      </button>
      <button id="expand" onClick={toggleSize}>
        <FontAwesomeIcon id="expand" icon={faCaretDown} />
      </button>
    </div>
  );
};

const InputList = ({handleInputs, projectId, version }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    const submitFetchInputs = async (projectId) => {

      const data = await fetchInputs(getAccessTokenSilently, user, projectId);
      console.log('Inputs:', data)
      setInputs(data.transcripts);
      handleInputs(data.transcripts);
      
    }
    submitFetchInputs(projectId);
  }, [getAccessTokenSilently, user, version, projectId]);

  const deleteInputHandler = async (inputId) => {
    if (window.confirm("Are you sure you want to delete this recording?")) {
      await deleteInput(getAccessTokenSilently, user, inputId);
      setInputs(inputs.filter(input => input._id !== inputId));
      handleInputs(inputs.filter(input => input._id !== inputId));
    }
  };

  return (
    <div>
      <h3 className="inputSectionHeader">Raw Notes</h3>
      {inputs && inputs.map((input, index) => (
        <InputCard key={index} input={input} deleteInputHandler={deleteInputHandler} />
      ))}
    </div>
  );
};


export default InputList;

import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const Dropdown = ({ options, initialValue, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.value === initialValue));

  const toggling = () => setIsOpen(!isOpen);

  let navigate = useNavigate();

  const onOptionClicked = value => () => {
    const option = options.find(option => option.value === value);
    setSelectedOption(option);
    setIsOpen(false);
    onValueChange(value);

    if (option.tag === 'Premium') {
        console.log("Redirecting to upgrade page")
        navigate("/upgrade");
      } else {
        console.log(selectedOption.tag + " is not premium")
      }

  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    // Listen for page clicks
    window.addEventListener('click', pageClickEvent);

    return () => {
      window.removeEventListener('click', pageClickEvent);
    }

  }, []);

  return (
    <div className="dropdown-section" ref={dropdownRef}>
      <button 
        onClick={toggling} 
        className="dropdown"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedOption.label} <FontAwesomeIcon id="expand" icon={faCaretDown} />
      </button>
      {isOpen && (
        <ul 
          className="dropdown-list" 
          role="listbox"
          tabIndex="-1"
        >
          {options.map((option, index) => (
            <li 
              key={index} 
              onClick={onOptionClicked(option.value)} 
              className="dropdown-item"
              role="option"
            >
              {option.label}
              {option.tag && <span className="dropdown-item-tag">{option.tag}</span>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

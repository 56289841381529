import React, { useRef } from 'react';
import { LoginButton } from './general_components/LoginLogout';
import { Link } from "react-router-dom"
import '../index.css';
import { ReactComponent as Logo } from './Logo.svg'; // Tell webpack this JS file uses this image
import Nav from './general_components/navbar';
import Footer from './general_components/Footer';

function Terms() {
    const heroAnimationRef = useRef(null);


    return (
        <body className="landing-body">
           <Nav />
            <div className="container container-body">
                <h1>Terms of Use</h1>
                <p>Last updated: July 14, 2023</p>
                <p>
                    COMING SOON!
                </p>

            </div>
            <Footer />
        </body>
    );
}

export default Terms;

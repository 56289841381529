import React, { useRef } from 'react';
import { LoginButton } from './general_components/LoginLogout';
import { Link, useNavigate } from "react-router-dom"
import '../index.css';
import { ReactComponent as Battle } from './LandingAssets/Battle.svg';
import { ReactComponent as Profound } from './LandingAssets/Profound.svg';
import { ReactComponent as Faster } from './LandingAssets/Faster.svg';
import { ReactComponent as Originality } from './LandingAssets/Originality.svg';
import heroimage from './LandingAssets/hero.png';
import Footer from './general_components/Footer';
import Nav from './general_components/navbar';

function Landing() {
    const heroAnimationRef = useRef(null);
    let navigate = useNavigate();


    return (
        <body className="landing-body">
            <Nav landing={true}/>
            <div className="container container-body">
                <div className="hero">
                    <div ref={heroAnimationRef}></div>
                    <h1>Refine raw ideas into shareable brilliance with AI</h1>
                    <h3 className='subhead'>Grow faster by structuring your authentic voice into high quality content with a button click. </h3>
                    <LoginButton text={"Get Started"} />
                    <img className='heroImage' src={heroimage} alt="product shot" />
                </div>
                <section className="testimonialsection">
                    <h3>You provide authenticity. We help you reach quality and consistency.</h3>
                    <div className="testimonials">
                    <div className="card testimonial">
                        <p>As a podcaster, having an efficient and streamlined workflow is essential. Thoughtfilter has greatly enhanced my workflow and proved to be extremely valuable</p>
                        <p>Juliana Reider <br/> Sr. Software Engineer at Slalom</p>
                    </div>
                    <div className="card testimonial">
                        <p>Doing bench research does not exactly prepare me for shooting fireworks out of my ass on youtube. Thoughtfilter evens the playing field and lets my message get heard. </p>
                        <p>Charles G. Pterosaur <br/> Researcher at Berkeley</p>
                    </div>
                    <div className="card testimonial">
                        <p>When I get back from a busy day as a PM at Amazon I don’t have energy to take detailed, organized notes on what I’ve learned. Thoughtfilter lets me free associate my ideas and shapes them into shareable content effortlessly </p>
                        <p>Chatsworth G. Ptolemy <br/> Product Manager at Amazon</p>
                    </div>
                    </div>
                </section>
                <section className="features">
                    <h2>Originality augmented.</h2>
                    <h3 className='subhead'>AI doesn’t do the thinking. That’s all on you. We just help you shape your ideas into something shareable.</h3>
                    <div className='featurerow'>


                    <div className="card feature-small">
                        <Originality className="featureImage"/>
                        <h3>Amplify your unique voice.</h3>
                        <p>Your originality can’t be replaced by AI. Focus on that and let us do the rest.</p>
                    </div>

                        <div className="card feature-small">
                            <Battle className="featureImage"/>
                            <h3>Battle-tested workflows.</h3>
                            <p>Made by and for people with something to say and not enough time to say it.</p>
                        </div>
                        </div>
                    <div className='featurerowrev'>

                    <div className="card feature-large">
                            <Profound className="featureImage"/>
                            <h3>Practical and profound.</h3>
                            <p>Convert rambling notes into a hero's journey, an advice-oriented podcast episode, or a personal summary that can spur your growth using cutting-edge psychology and AI.</p>
                        </div>

                    <div className="card feature-large">
                        <Faster className="featureImage"/>
                        <h3>Ship faster.</h3>
                        <p>Thoughtfilter made our podcasting workflow 56% faster.</p>
                    </div>

                    </div>
                </section>
            </div>
            <Footer />
        </body>
    );
}

export default Landing;

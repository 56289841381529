import React from "react";
import '../../index.css';
import { useNavigate } from "react-router-dom"
import { ReactComponent as Logo } from '../Logo.svg'; // Tell webpack this JS file uses this image
import { LoginButton, LogoutButton } from './LoginLogout';

function Nav({landing=false}) {
    let navigate = useNavigate();
    let landing_nav_items = landing ? "flex" : "none"
    let app_nav_items = landing ? "none" : "flex"

    return (<header>
        <ul className="nav">
            <a className="logoLink" onClick={() => navigate('/')}><Logo className="logo" /></a>
            <div className="nav-items" style={{display: landing_nav_items}}>
                <li className="nav-item"><a onClick={() => navigate('/upgrade')}>Pricing</a></li>
                <li className="nav-item"><a onClick={() => navigate('/contact')}>Contact Us</a></li>
                <LoginButton text="Sign up" id="navButton" />
            </div>
            <div className="nav-items" style={{display: app_nav_items}}>
                <LogoutButton text="Log out" id="navButton" />
            </div>
        </ul>
    </header>)
}

export default Nav;